// Invalid avatar code errors
export class InvalidAcessCodeError extends Error {
  constructor() {
    super();
    this.name = 'InvalidAcessCode';
    this.message = 'Access code is invalid';
  }
}

// UnAuthorized Access to avatar error
export class UnAuthorizedAccessError extends Error {
  constructor() {
    super();
    this.name = 'UnAuthorizedAccess';
    this.message = 'User is UnAuthorized to use this AccessCode';
  }
}

// Avatar linked to another worker error
export class AvatarLinkedToAnotherWorkerError extends Error {
  constructor() {
    super();
    this.name = 'AvatarLinkedToAnotherWorker';
    this.message = 'Avatar is already linked to another worker';
  }
}

// Worker has another avatar for the same project
export class UniqueProjectAvatarConflictError extends Error {
  constructor() {
    super();
    this.name = 'UniqueProjectAvatarConflictError';
    this.message = 'Worker has another avatar for the same project';
  }
}

// Worker has another avatar for the same task
export class UniqueTaskAvatarConflictError extends Error {
  constructor() {
    super();
    this.name = 'UniqueTaskAvatarConflictError';
    this.message = 'Worker has another avatar for the same task';
  }
}

export class AccesscodePhonenumberMismatchError extends Error {
  constructor() {
    super();
    this.name = 'AccesscodePhonenumberMismatchError';
    this.message = `AccessCode can't be used by this phonenumber`;
  }
}

export class ProfileNotValidatedError extends Error {
  constructor() {
    super();
    this.name = 'ProfileNotValidatedError';
    this.message = 'Your Profile is Not Validated Yet or Under Review';
  }
}

export class ProfileInvalidError extends Error {
  constructor() {
    super();
    this.name = 'ProfileInvalidError';
    this.message = 'Your Profile Data is Invalid. Ask the Coordinator to enter again.';
  }
}

export class AvatarExtrasMatchWorkerAddressError extends Error {
  constructor(message?: string) {
    super();
    this.name = 'AvatarExtrasMatchWorkerAddressError';
    this.message = message ?? 'Avatar Extras does not match Worker Address';
  }
}

export class GenderNotAllowedError extends Error {
  constructor(message?: string) {
    super();
    this.name = 'GenderNotAllowedError';
    this.message =
      message ?? 'Apologies. Currently we are not allowing any new registrations of this gender for this Task';
  }
}
